import axios from 'axios';
import config from '@/config/env-constants';


function getProjectId() {
    if (config.currEnv === 'pjl') {
        return `ayun-${config.currEnv}`;
    } else {
        return `ares-${config.currEnv}-app`;
    }
}

export default {

    baseUrl: `https://us-central1-${getProjectId()}.cloudfunctions.net`,

    getInventorySummaryReport(page, limit, startAt, filterBy, view, currUserId) {
        let url = `${this.baseUrl}/getInventorySummaryReport`;
        return axios.post(url, {
            page,
            limit,
            startAt,
            view: view,
            currUserId: currUserId,
            filterBy: JSON.stringify(filterBy)
        });
    },

    getInventoryComplianceReport(filterBy, view, currUserId) {
        let url = `${this.baseUrl}/getInventoryComplianceReport`;
        return axios.post(url, {
            view: view,
            currUserId: currUserId,
            filterBy: JSON.stringify(filterBy)
        });
    },

    getInventoryScanProgressReport(filterBy, view, currUserId) {
        let url = `${this.baseUrl}/getInventoryScanProgressReport`;
        return axios.post(url, {
            view: view,
            currUserId: currUserId,
            filterBy: JSON.stringify(filterBy)
        });
    },

    getAssetsWithTruckersReport(filterBy, view, currUserId) {
        let url = `${this.baseUrl}/getAssetsWithTruckersReport`;
        return axios.post(url, {
            view: view,
            currUserId: currUserId,
            filterBy: JSON.stringify(filterBy)
        });
    },

    getUserInventoryScansReport(filterBy, view, currUserId) {
        let url = `${this.baseUrl}/getUserInventoryScansReport`;
        return axios.post(url, {
            view: view,
            currUserId: currUserId,
            filterBy: JSON.stringify(filterBy)
        });
    }
}